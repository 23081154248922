<template>
<div>

    <v-container>

        <v-row>
            <v-col cols="12">
                <s-toolbar :color="'#244093'" dark label="Reporte Resoluciones" class="tran"></s-toolbar>
                <v-row style="margin: auto">
                    <v-card width="100%">

                        <v-row style="margin: auto">
                            <v-col cols="4" lg="4" md="4">
                                <s-select-definition :def="1131" v-model="objMonth" return-object label="Seleccione mes"></s-select-definition>
                            </v-col>
                            <v-col cols="4" lg="4" md="4">
                                <s-text label="Ingrese año" number v-model="txtYear"></s-text>
                            </v-col>
                            <v-col cols="4" lg="2" md="2" class="mt-4 pl-0">
                                <v-btn rounded :color="'info'" small @click="runView()">Buscar</v-btn>
                                <v-btn rounded :color="'error'" small @click="downloadReport()">Descargar</v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
    <v-container>

        <v-tabs v-model="currentItem">
            <v-tab href="#ReportResolutions"> Reporte Resoluciones</v-tab>
        </v-tabs>

        <v-tabs-items v-model="currentItem">
            <v-tab-item :value="'ReportResolutions'">
                <v-col cols="12">
                    <v-row>
                        <v-col cols="12">
                            <v-data-table :height="items.length > 0 ? '400px': 'auto'" :headers="headers" :items="items" dense :footer-props="{
                        showCurrentPage: true,
                        showFirstLastPage: true,
                        itemsPerPageOptions: [5, 10, 50, 100],
                    }">
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-col>
            </v-tab-item>

        </v-tabs-items>

        <v-dialog v-model="processing" :persistent="messageProcessing.length == 0" width="400">
            <v-card color="primary" dark>
                <v-card-text v-if="messageProcessing.length == 0">
                    Por favor espere
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
                <v-card-text v-else>
                    <div class="text--white pt-4">
                        Error al realizar busqueda <br />
                        {{ messageProcessing }}
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</div>
</template>

<script>
import _sQryConfigurationService from "@/services/QueryManager/QryConfigurationService.js";

export default {
    data() {
        return {
            currentItem: "tab-Funciones",
            items: [],
            headers: [],
            report: {},

            processing: false,
            messageProcessing: "",
            objMonth: null,
            txtYear: this.$fun.getYear(),
        }

    },
    methods: {
        runView() {
            this.messageProcessing = ""
            this.report.QryNameProcedure = "";
            this.report.QryParamsArray = "Month, Year"
            this.report.QryParamsArrayValue = this.objMonth.DedDescription + "," + this.txtYear
            this.report.QryNameProcedure = "ClcReportResolutions_R";

            this.processing = true;
            _sQryConfigurationService
                .querygeneral(this.report, this.$fun.getUserID())
                .then(
                    (resp) => {
                        if (resp.status == 200) {
                            this.processing = false;
                            this.items = resp.data;

                            for (
                                var i = 0; i < Object.keys(this.items[0]).length; i++
                            ) {
                                this.headers.splice(i, i, {
                                    text: Object.keys(this.items[0])[i],
                                    value: Object.keys(this.items[0])[i],
                                    width: "120",
                                    sortable: false,
                                });
                            }

                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        },

        downloadReport() {
            this.messageProcessing = ""
            this.report.QryNameProcedure = "";
            this.report.QryParamsArray = "Month, Year"
            this.report.QryParamsArrayValue = this.objMonth.DedDescription + "," + this.txtYear
            this.report.QryNameProcedure = "ClcReportResolutions_R";

            this.processing = true;
            _sQryConfigurationService
                .downloadexcel(this.report, this.$fun.getUserID())
                .then(
                    (r) => {
                        if (r.status == 200) {
                            this.processing = false;

                            this.$fun.downloadFile(
                                r.data,
                                this.$const.TypeFile.EXCEL,
                                "Reporte de Resoluciones"
                            );
                        }
                    },
                    (e) => {
                        this.messageProcessing = e.response.data.Message;
                        this.processing = true;
                    }
                );
        }
    }
};
</script>

<style>
.tran {
    opacity: 0.7 !important;
}
</style>
